.BannerDesk {
 display: inline;
}

.BannerMob {
    display: none;
}

.ContainerBanner {
    position: relative;
    width: 98%;
    height: 19vw;
    box-shadow: 0.65vw 0.65vw 0vw #0D1675;
    border: none;
    margin: 4% 0% 5% 0.2%;
}

.ContainerBanner img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .BannerDesk {
        display: none;
    }
       
    .BannerMob {
        display: inline;
    }

    .ContainerBanner {
        height: 43vw;
    }
}