.form-desk{
    display: inline;
}

.form-mob{
    display: none;
}


.bttn_formRegistro_css {
    display: inline-block;
    border: none;
    outline: none;
    margin-top: 3%;
    height: 3vw;
    width: 20%;
    background: none;
    border: transparent solid 1px;
    border-bottom: none;
    position: relative;

    font-family: Roboto-Medium;
    font-size: 1.52vw;
    color: #1D3772;
}
.bttn_formRegistro_css:after {
    content:"";
    background: -webkit-linear-gradient(left, #25C8F6 0%, #0078C1 100%);
    border-radius: 6px;
    display: block;
    height: 0.6vw;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.bttn_formRegistro_css:focus{
    border: none;
    outline: none;
}

.bttn_formRegistro_css:active{
    border: none;
    outline: none;
}

.bttn_formRegistro_css:hover::after{
    transition: all ease-in-out 0.5s;
    left: 10%;
    width: 80%;
}

.separator-div{
    width: 100%;
    height: 4vw;
    display: inline-block;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .form-desk{
        display: none;
    }
    
    .form-mob{
        display: inline;
    }

    .contacto-servicios {
        border-radius: 0.5vw;
        outline: none;
        width: 82%;
        font-size: 1.1vw;
        margin-top: 1.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }

    .form-container{
        border: #0078C1;
        border-style: solid;
        padding: 0% 6% 0% 6%;
        border-radius: 2vw;
        height: 106%;
        margin-top: 8vw;
    }

    .subtitle-sectionsFM{
        width: 100%;
        font-size: 4.15vw;
        margin-top: 4vw;

    }

    .content-sectionsFM{
        width: 100%;
        font-size: 3.1vw;
    }

    .contacto-serviciosFM {
        border-radius: 1.5vw;
        outline: none;
        width: 100%;
        font-size: 3.1vw;
        margin-top: 3.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }


    .subtitle-sectionsFM{
        display: inline-block;
        width: 85%;
        font-size: 4vw;
        font-family: Roboto-Bold;
        letter-spacing: 0px;
        color: #0078C1;
        opacity: 1;
    }

    .bttn_formRegistro_css{
        width: 30%;
        height: 8vw;
        font-size: 4vw;
    }
}