.num-tel-btncontactanos{
    font-family: Roboto-Bold;
    color: #122E5F !important;
}

.icon-what-contactanos{
    position: absolute;
    top: -50%;
    width: 2vw;
}

/* a partir de aquí BOTÓN CONTACTANOS */
.formato-btngral-contactanos{
    cursor: pointer;
    font-family: Roboto-Regular;
    color: #0078C1;
    font-size: 1.83vw;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-contactanos::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #51B8B6;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: 13%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-contactanos:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-contactanos p{
    margin-bottom: 0.9vw;
}

.div-icon-what{
    position: absolute;
    top: -50%;
    right: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .formato-btngral-contactanos::after{
        height: 0.45rem;
        width: 100%;
        left: 0;
    }

    .formato-btngral-contactanos:hover::after{
        left: 0%;
        width: 100%;
    }
    .formato-btngral-contactanos{
        font-size: 0.83rem;
    }
    .formato-btngral-contactanos p{
        margin-bottom: 0.8rem;
    }

    .icon-what-contactanos{
        width: 5vw;
    }
}