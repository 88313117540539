.componentAnimServicios-desk{
    display: inline;
}

.componentAnimServicios-mobile{
    display: none;
}

.container-todoslosservicios{
    width: 100vw;
    height: 59vw;
    background: rgb(1,7,57);
    background: radial-gradient(circle, rgba(1,7,57,1) 1%, rgba(1,7,46,1) 46%, rgba(1,7,29,1) 100%);
}

.div-logobbp-animServices{
    position: relative;
    width: 100%;
    height: 3vw;
}

.logobbp-animServices{
    width: 7.5vw;
    margin-top: 2%;
    margin-left: 45%;
}

.div-imagenes{
    width: 100%;
    height: 49vw;
}

.txt-descripciondeservicio{
    position: absolute;
    font-size: 1vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    color: #ffff;
}

.img-fondo-circulo{
    position: relative;
    transform: translate(-50%,-50%);
    top: 42%;
    max-height: 5vw;
}

.circulo{
    cursor: pointer;
    position: absolute;
    float: left;
    width: 38%;
    height: 9.2vw;
    border-radius: 50% 50%;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 100%);
    opacity: 1;
    z-index: 1;
}

.vermas{
    width: 60%;
    text-align: center;
    display: inline-block;
    color: rgb(255, 255, 255);
    position: relative;
    transform: translate(-50%,-50%);
    left: 50%;
    font-size: 0.85vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
}

.circulo:hover{
    background-image: none;
}

.circulo:hover > .img-fondo-circulo{
    transform: translate(-50%,-50%) scale(1.15);
    transition: all 500ms ease;
}

.flechita{
    position: absolute;
    float: left;
    width: 26%;
    max-height: 5vw;
}

.div-boton{
    width: 100%;
    height: 8vw;
    padding: 2% 7% 2% 1%;
    text-align: center;
}

/* animación css */

.efaGep {
    color: #1D3772 !important;
    padding-left: 0.25vw !important;
}

.rotatemedcirculo {
    animation: rotate linear infinite; -webkit-animation: rotate linear infinite;
    transform-origin: 50% 50%; -webkit-transform-origin: 50% 50%;
}
  
@keyframes rotate 
{
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
@-webkit-keyframes rotate
{
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}

.contenedorservicios{
    -webkit-animation: fadein ease-in normal ;
    -moz-animation: fadein ease-in normal ;
    animation: fadein ease-in normal ;
}

.flechitaAnim{
    -webkit-animation: fadein ease-in-out reverse infinite;
    -moz-animation: fadein ease-in-out reverse infinite;
    animation: fadein ease-in-out reverse infinite;
}

@-webkit-keyframes fadein {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

.txt-nombredeservicio{
    position: relative;
    font-size: 2.2vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #00B6ED;
}

.glitch {
    color: #00B6ED;
    font-size: 2.2vw;
    position: relative;
    font-family: Roboto-Bold;
}

.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 3px;
    text-shadow: -1px 0 red;
    top: 0;
    color: #00B6ED;
    background: #01072E;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}
    
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: 1px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: #00B6ED;
    background:#01072E;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}

@keyframes noise-anim {
    0% { clip: rect(51px, 9999px, 28px, 0);}
    5% { clip: rect(70px, 9999px, 19px, 0);}
    10% { clip: rect(92px, 9999px, 13px, 0);}
    15% { clip: rect(85px, 9999px, 49px, 0);}
    20% { clip: rect(45px, 9999px, 56px, 0);}
    25% { clip: rect(26px, 9999px, 31px, 0);}
    30% { clip: rect(62px, 9999px, 73px, 0);}
    35% { clip: rect(34px, 9999px, 11px, 0);}
    40% { clip: rect(26px, 9999px, 61px, 0);}
    45% { clip: rect(34px, 9999px, 71px, 0);}
    50% { clip: rect(35px, 9999px, 69px, 0);}
    55% { clip: rect(92px, 9999px, 81px, 0);}
    60% { clip: rect(26px, 9999px, 85px, 0);}
    65% { clip: rect(2px, 9999px, 68px, 0);}
    70% { clip: rect(54px, 9999px, 79px, 0);}
    75% { clip: rect(35px, 9999px, 85px, 0);}
    80% {clip: rect(70px, 9999px, 60px, 0);}
    85% {clip: rect(32px, 9999px, 26px, 0);}
    90% {clip: rect(94px, 9999px, 53px, 0);}
    95% {clip: rect(99px, 9999px, 92px, 0);}
    100% {clip: rect(42px, 9999px, 27px, 0);}
}

@keyframes noise-anim-2 {
    0% {clip: rect(21px, 9999px, 49px, 0);}
    5% {clip: rect(27px, 9999px, 52px, 0);}
    10% {clip: rect(21px, 9999px, 70px, 0);}
    15% {clip: rect(65px, 9999px, 25px, 0);}
    20% {clip: rect(24px, 9999px, 10px, 0);}
    25% {clip: rect(61px, 9999px, 64px, 0);}
    30% {clip: rect(34px, 9999px, 47px, 0);}
    35% {clip: rect(46px, 9999px, 93px, 0);}
    40% {clip: rect(46px, 9999px, 9px, 0);}
    45% {clip: rect(67px, 9999px, 36px, 0);}
    50% {clip: rect(72px, 9999px, 20px, 0);}
    55% {clip: rect(79px, 9999px, 26px, 0);}
    60% {clip: rect(13px, 9999px, 87px, 0);}
    65% {clip: rect(10px, 9999px, 25px, 0);}
    70% {clip: rect(85px, 9999px, 11px, 0);}
    75% {clip: rect(94px, 9999px, 35px, 0);}
    80% {clip: rect(50px, 9999px, 86px, 0);}
    85% {clip: rect(74px, 9999px, 67px, 0);}
    90% {clip: rect(1px, 9999px, 40px, 0);}
    95% {clip: rect(3px, 9999px, 48px, 0);}
    100% {clip: rect(90px, 9999px, 23px, 0);}
}
    

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentAnimServicios-desk{
        display: none;
    }
    
    .componentAnimServicios-mobile{
        display: inline;
    }

    .container-todoslosservicios{
        height: 268vw;
    }

    .div-imagenes{
        height: 245vw;
    }

    .div-boton{
        height: 20vw;
    }

    .div-logobbp-animServices{
        height: 15vw;
    }

    .logobbp-animServices {
        width: 22vw;
        margin-top: 4%;
        margin-left: 40%;
    }
    
    .circulo{
        width: 54%;
        height: 26.2vw;
    }

    .img-fondo-circulo{
        top: 45%;
        max-height: 17vw;
    }

    .vermas{
        font-size: 0.69rem;
    }

    .glitch{
        font-size: 0.8rem;
    }

    .txt-descripciondeservicio{
        font-size: 0.5rem;
    }
    
    .circulo:hover{
        background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 100%);

    }
    
    .circulo:hover > .img-fondo-circulo{
        transform: translate(-50%,-50%) scale(1.05);
    }
}
