.componentHeader-desk{
    display: inline;
}

.componentHeader-mobile{
    display: none;
}

.header {
    position: absolute;
    top: 0;
    width: 100vw;
    /*padding: 0.5% 13%;*/
    padding: .5% 26% .5% 23.2%;
    height: 4.5vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .box-header{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
}
.thinkcaresLogoAzul{
    cursor: pointer;
    width: 10vw;
    height: 4vw;
}

.ajustes-thinkcaresLogoAzul{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menu-header{
    width: 75%;
    /*justify-content: space-between;*/
    justify-content: space-between;
    padding: 0 0 0 10%;
    display: flex;
    align-items: center;
    color: #00347D;
    opacity: 1;
}

.menu-header p{
    cursor: pointer;
    font-size: 1.5vw; /*1.1vw*/
    font-family: Roboto-Medium;
}

.headerSub {
    width: 100vw;
    padding: 0.3% 16% 0.3% 21%;
    height: 7vw;
    background: #F0F9FC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .box-headerSub{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.div-headerSub{
    cursor: pointer;
    position: relative;
    float: center;
}

.div-headerSub *{
    transition:0.2s;
}

.div-headerSub:hover .img-headerSub{
    transform: translate(-50%,-50%) scale(1.15);
}
.div-headerSub:hover .p-headerSub{
    transform: translate(-50%,-35%) scale(1.2);
}

.img-headerSub{
    position: absolute;
    width: 4vw;
    transform: translate(-50%,-50%);
    object-fit: cover;
    object-position: 100% 50%;
}

.p-headerSub{
    position: inherit;
    transform: translate(-50%,-35%);
    text-align: center;
    margin-bottom: 0;
    top: 3vw;
    font-size: 1.1vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentHeader-desk{
        display: none;
    }

    .componentHeader-mobile{
        display: inline;
    }

    .icons-header-mob{
        width: 7vw;
    }

    .thinkcaresLogoAzul{
        width: 10vw;
        height: 8vw;
    }

    .headerSub{
        padding: 1.5% 0% 0.5% 4%;
        height: 15vw;
    }

    .box-headerSub{
        display: flex;
        justify-content: space-evenly;
        padding-left: 8%;
    }

    .img-headerSub{
        width: 9.5vw;
    }

    .p-headerSub{
        top: 6vw;
        font-size: 1.8vw;
    }

    .borderBottomLogo{
        border-bottom: 0.7vw solid #25C8F6;;
    }

    .borderBottomMobile{
        border-bottom: 0.2vw solid #25C8F6;;
    }

    .borderBottomMobile a{
        margin-left: 13%;
    }

    .div-logoMobile{
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 20%; /* 15 */
    }

    .div-logoMobileItems{
        position: relative;
        display: flex;
        align-items: center;
        min-height: 10%; /* 8 */
    }

    .div-logoMobileSubitems{
        position: absolute;
        display: inline-block;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        line-height: 1.65;
        padding: 0 2% 0 13%;
    }

    .div-logoMobileSubitems li>div{
        width: 8%;
        display: inline-block;
    }

    .div-lisMenuMobile {
        width: 100%;
    }

    .div-logoMobileBotonGral{
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 26%; /* 15 */
    }

    .div-logoMobileBotonGralSubmenu{
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 45%; /* 34 */
    }

    .flechita-serviciosmenu{
        margin-left: 32%;
        width: 2.5vw;
        transform: rotate(90deg);
    }

    .flechita-serviciosmenu-submenu{
        margin-left: 32%;
        width: 2.5vw;
        transform: rotate(270deg);
    }

    /* CSS para menú mobile - Toolbar */
    
    .toolbar{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #122E5F 0% 0% no-repeat padding-box;
        height: 12vw;
    }

    .toolbar_navigation{
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 5vw 0 5vw;
    }

    .toolbar_logo{
        margin-left: 1vw;
    }

    .toolbar_logo a{
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.5rem;
    }

    .toolbar_navigation_items ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    .toolbar_navigation_items li{
        padding: 0 0.5vw;
    }

    .toolbar_navigation_items a{
        color: white;
        text-decoration: none;
    }

    .toolbar_navigation_items a:hover,
    .toolbar_navigation_items a:active{
        color: steelblue;
    }

    /* CSS para menú mobile - DrawerToggleButton */

    .toggle-button{
        height: 6.4vw;
        width: 7vw;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .toggle-button:focus{
        outline: none;
    }

    .toggle-button_line{
        width: 7vw;
        height: 3px;
        background: white;
    }

    /* CSS para menú mobile - SideDrawer */

    .side-drawer{
        height: 100%;
        background: #122E5F 0% 0% no-repeat padding-box;
        box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 72%;
        z-index: 200;
        transform: translateX(-120%);
        transition: transform 0.3s ease-out;
    }

    .side-drawer.open{
        transform: translateX(0);
    }

    .side-drawer ul{
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
    }

    .side-drawer div{
        color: #FFFFFF;
        text-decoration: none;
        font-family: Roboto-Medium;
        font-size: 1.4rem;
    }

    .side-drawer a:hover,
    .side-drawer a:active{
        color: steelblue;
    }

    /* CSS para menú mobile - BackDrop */

    .backdrop{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 100;
    }

}