.componentFooter-desk{
  display: inline;
}

.componentFooter-mobile{
  display: none;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100vw;
    text-align: center;
    padding: 1% 0;
    height: 8vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .cursor{
    cursor: pointer;
  }

  .box-footer{
    width: 100%;
  }

  .div-icons{
    height: 8vw;
    padding: 1% 8%;
    background: #122E5F 0% 0% no-repeat padding-box;
    border-top: 0.5vw solid #25C8F6;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .div-icon-menu-servicios{
    float: left;
  }

  .icon-menu-servicios{
    width: 2vw;
    max-height: 1vw;
    margin-right: 1vw;
    margin-bottom: 0.3vw;
  }

  .txt-servicios-footer{
    text-align: center;
    font-family: Roboto-Bold;
    font-size: 1.52vw;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
  }

  .icons-footer-efect{
    cursor: pointer;
    transform: translate(-50%,-50%);
    top:50%;
    left: 50%;
  }

  .icons-footer-efect:hover{
    transform: translate(-50%,-50%) scale(1.25);
    transition: all 500ms ease;
  }

  .div-txtfooter{
    font-size: 1.2vw;
    font-family: Roboto-Regular;
    color: #ffff;
  }

  .div-txtfooter p{
    margin: 0;
  }

  .div-txtfooter div{
    display: flex;
    justify-content: space-evenly;
  }

  .div-servicios{
    width: 100%;
    height: 25vw;
    background: #122E5F 0% 0% no-repeat padding-box;
    box-shadow: 0vw 0.2vw 0.3vw #00000029;
    border-top: 0.05vw solid #ffff;
    color: #ffff;
    opacity: 1;
    padding: 3% 5%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .div-servicios p{
    margin-bottom: 0;
    font-family: Roboto-Regular;
    font-size: 1.4vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .div-servicios > div > div{
    cursor: pointer;
    margin-bottom: 1vw;
    font-family: Roboto-Medium;
    font-size: 1.62vw;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div-servicios > div > div:hover{
    transform: scale(1.15);
    transition: all 150ms ease;
    margin-left: 0.8vw;
    border-bottom: 0.2vw solid #0078C1;
  }

  .divnfs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .fs {
    width: 0.8vw;
    height: 1.8vw;
  }

@media screen and (max-width: 687px), (max-height: 325px) {
  .componentFooter-desk{
    display: none;
  }
  
  .componentFooter-mobile{
    display: inline;
  }

  .footer{
    height: 28vw;
  }

  .div-icons{
    height: 32vw;
    flex-direction: column;
    justify-content: center;
    border-top: 1.5vw solid #25C8F6;
    padding: 4% 8% 5%;
  }

  .icon-menu-servicios{
    max-height: 3vw;
    width: 4vw;
    margin-right: 2.25vw;
  }

  .txt-servicios-footer{
    font-size: 3.5vw;
  }

  .div-txtfooter{
    font-size: 2vw;
    padding: 2% 0 1%;
  }

  .icons-footer-efect{
    cursor: pointer;
    transform: translate(25%,-35%);
    top:50%;
    left: 50%;
  }

  .icons-footer-efect:hover{
    transform: translate(25%,-35%) scale(1.25);
    transition: all 500ms ease;
  }

  .div-servicios{
    height: 110vw;
    border-top: 0.1vw solid #ffff;
    padding: 6% 8%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*align-items: flex-start;*/
  }

  .div-servicios > div > div{
    font-size: 3.5vw;
  }

  .div-servicios p{
    font-size: 2.7vw;
  }

  .fs {
    width: 2.5vw;
    height: 3vw;
  }
}