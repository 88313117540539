.animsvg-root{
    display: inline-block;
    position: relative;
}
.animsvg-frame{
    display: inline-block;
    position: absolute;
    left: 0;
    top:0;
    transition: opacity 0.5s ease;
}