.componentInscribeteBlog-desk{
    display: inline;
}

.componentInscribeteBlog-mobile{
    display: none;
}

.titulo-proyectosdestacados{
    margin-left: 14%;
    font-size: 1.8vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #00347D;
    opacity: 1;
}

.box-nuestroBlog{
    position: relative;
    width: 100vw;
}

.nuestroBlog{
    width: 100%;
    height: 25vw;
    background: transparent linear-gradient(360deg, #005EFC 0%, #25C8F6 100%) 0% 0% no-repeat padding-box;
    opacity: 0.15;
}

.container-elementos{
    position: absolute;
    width: 86%;
    top: 4vw;
    left: 6%;
}

.contacto-nuestroBlog{
    border-radius: 0.8vw;
    outline: none;
    width: 40%;
    border-width: thin;
    font-size: 1.32vw;
    margin-top: 1vw;
    padding: 1% 1.5%;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.18vw solid #0086FC;
}

.contacto-nuestroBlog::placeholder{
    color: #707070;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
}

.div-botones{
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.num-tel{
    font-family: Roboto-Bold !important;
    color: #122E5F !important;
    margin-bottom: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentInscribeteBlog-desk{
        display: none;
    }

    .componentInscribeteBlog-mobile{
        display: inline;
    }

    .container-elementos{
        top: 6%;
    }

    .nuestroBlog{
        height: 64vw;
    }

    .div-botones{
        height: 25vw;
    }

    .contacto-nuestroBlog{
        width: 100%;
        font-size: 3.2vw;
        padding: 3% 3.5%;
        margin-top: 2vw;
        margin-bottom: 3vw;
    }

    .contacto-nuestroBlog::placeholder{
        font-size: 3.2vw;
    }
}