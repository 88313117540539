body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-sections{
  width: 100vw;
  padding: 4.5% 6% 1%;
}

.title-sections{
  font-size: 6.58vw;
  font-family: Roboto-Bold;
  line-height: 1.05;
  letter-spacing: 0px;
  color: #1D3772;
  opacity: 1;
}

.title2-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.title3-sections{
  width: 66%;
  display: inline-block;
  font-size: 1.91vw;
  font-family: Roboto-Medium;
  letter-spacing: 0px;
  color: #122E5F;
  margin-bottom: 4vw;
  opacity: 1;
}

.subtitle-sections{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #0078C1;
  opacity: 1;
}

.subtitle-sections2{
  display: inline-block;
  width: 85%;
  font-size: 2.2vw;
  font-family: Roboto-Bold;
  letter-spacing: 0px;
  color: #25C8F6;
  opacity: 1;
}

.content-sections{
  display: inline-block;
  width: 78%;
  font-size: 1.32vw;
  font-family: Roboto-Regular;
  letter-spacing: 0px;
  color: #122E5F;
  opacity: 1;
}


@media screen and (max-width: 687px), (max-height: 325px) {
  .container-sections{
    padding: 8% 6% 1%;
  }
  
  .title-sections{
    width: 100%;
    font-size: 10.3vw;
  }

  .title2-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .title3-sections{
    width: 100%;
    font-size: 4.7vw;
  }
  
  .subtitle-sections{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .subtitle-sections2{
    width: 100%;
    font-size: 5.15vw;
  }
  
  .content-sections{
    width: 100%;
    font-size: 4.7vw;
  }

  .div_separatorBootom_formRegister{
    width: 100%;
    height: 10vw;
    display: inline-block;
  }
}


@font-face {
  font-family: Roboto-Black;
  src: url(/static/media/Roboto-Black.d13b7303.woff);
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/static/media/Roboto-BlackItalic.1d3e9d67.woff);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(/static/media/Roboto-Bold.5058c3f7.woff);
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/static/media/Roboto-BoldItalic.5a14c4ef.woff);
}

@font-face {
  font-family: Roboto-Italic;
  src: url(/static/media/Roboto-Italic.c33a6de8.woff);
}

@font-face {
  font-family: Roboto-Light;
  src: url(/static/media/Roboto-Light.08e6b787.woff);
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url(/static/media/Roboto-LightItalic.a15a04ba.woff);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(/static/media/Roboto-Medium.977fec48.woff);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/static/media/Roboto-MediumItalic.ee1fe5c3.woff);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(/static/media/Roboto-Regular.5aa400b9.woff);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(/static/media/Roboto-Thin.ac74d83c.woff);
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/static/media/Roboto-ThinItalic.74918f4f.woff);
}

@font-face {
  font-family: GandhiSans-Bold;
  src: url(/static/media/GandhiSans-Bold.dd39fc91.otf);
}

@font-face {
  font-family: JustTellMeWhat-Italic;
  src: url(/static/media/JustTellMeWhat-Italic.e86e69fb.otf);
}

@font-face {
  font-family: JustTellMeWhat-Regular;
  src: url(/static/media/JustTellMeWhat-Regular.b9937132.otf);
}
.App{
    position: relative;
    min-height:100vh;
    min-width: 100vw;
    padding-top: 4.5vw; /* espacio header */
    padding-bottom: 8vw; /* espacio footer */
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .App{
        padding-top: 12vw;
        padding-bottom: 28vw;
    }
}
.componentHeader-desk{
    display: inline;
}

.componentHeader-mobile{
    display: none;
}

.header {
    position: absolute;
    top: 0;
    width: 100vw;
    /*padding: 0.5% 13%;*/
    padding: .5% 26% .5% 23.2%;
    height: 4.5vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

 .box-header{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: space-around;
            align-content: space-around;
}
.thinkcaresLogoAzul{
    cursor: pointer;
    width: 10vw;
    height: 4vw;
}

.ajustes-thinkcaresLogoAzul{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.menu-header{
    width: 75%;
    /*justify-content: space-between;*/
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 0 0 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #00347D;
    opacity: 1;
}

.menu-header p{
    cursor: pointer;
    font-size: 1.5vw; /*1.1vw*/
    font-family: Roboto-Medium;
}

.headerSub {
    width: 100vw;
    padding: 0.3% 16% 0.3% 21%;
    height: 7vw;
    background: #F0F9FC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

 .box-headerSub{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-content: center;
            align-content: center;
}

.div-headerSub{
    cursor: pointer;
    position: relative;
    float: center;
}

.div-headerSub *{
    transition:0.2s;
}

.div-headerSub:hover .img-headerSub{
    -webkit-transform: translate(-50%,-50%) scale(1.15);
            transform: translate(-50%,-50%) scale(1.15);
}
.div-headerSub:hover .p-headerSub{
    -webkit-transform: translate(-50%,-35%) scale(1.2);
            transform: translate(-50%,-35%) scale(1.2);
}

.img-headerSub{
    position: absolute;
    width: 4vw;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    object-fit: cover;
    object-position: 100% 50%;
}

.p-headerSub{
    position: inherit;
    -webkit-transform: translate(-50%,-35%);
            transform: translate(-50%,-35%);
    text-align: center;
    margin-bottom: 0;
    top: 3vw;
    font-size: 1.1vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentHeader-desk{
        display: none;
    }

    .componentHeader-mobile{
        display: inline;
    }

    .icons-header-mob{
        width: 7vw;
    }

    .thinkcaresLogoAzul{
        width: 10vw;
        height: 8vw;
    }

    .headerSub{
        padding: 1.5% 0% 0.5% 4%;
        height: 15vw;
    }

    .box-headerSub{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        padding-left: 8%;
    }

    .img-headerSub{
        width: 9.5vw;
    }

    .p-headerSub{
        top: 6vw;
        font-size: 1.8vw;
    }

    .borderBottomLogo{
        border-bottom: 0.7vw solid #25C8F6;;
    }

    .borderBottomMobile{
        border-bottom: 0.2vw solid #25C8F6;;
    }

    .borderBottomMobile a{
        margin-left: 13%;
    }

    .div-logoMobile{
        position: relative;
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        height: 20%; /* 15 */
    }

    .div-logoMobileItems{
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        min-height: 10%; /* 8 */
    }

    .div-logoMobileSubitems{
        position: absolute;
        display: inline-block;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        line-height: 1.65;
        padding: 0 2% 0 13%;
    }

    .div-logoMobileSubitems li>div{
        width: 8%;
        display: inline-block;
    }

    .div-lisMenuMobile {
        width: 100%;
    }

    .div-logoMobileBotonGral{
        position: relative;
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        height: 26%; /* 15 */
    }

    .div-logoMobileBotonGralSubmenu{
        position: relative;
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        height: 45%; /* 34 */
    }

    .flechita-serviciosmenu{
        margin-left: 32%;
        width: 2.5vw;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .flechita-serviciosmenu-submenu{
        margin-left: 32%;
        width: 2.5vw;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }

    /* CSS para menú mobile - Toolbar */
    
    .toolbar{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #122E5F 0% 0% no-repeat padding-box;
        height: 12vw;
    }

    .toolbar_navigation{
        width: 100%;
        display: -webkit-flex;
        display: flex;
        height: 100%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 0 5vw 0 5vw;
    }

    .toolbar_logo{
        margin-left: 1vw;
    }

    .toolbar_logo a{
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.5rem;
    }

    .toolbar_navigation_items ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: -webkit-flex;
        display: flex;
    }

    .toolbar_navigation_items li{
        padding: 0 0.5vw;
    }

    .toolbar_navigation_items a{
        color: white;
        text-decoration: none;
    }

    .toolbar_navigation_items a:hover,
    .toolbar_navigation_items a:active{
        color: steelblue;
    }

    /* CSS para menú mobile - DrawerToggleButton */

    .toggle-button{
        height: 6.4vw;
        width: 7vw;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        box-sizing: border-box;

        display: -webkit-flex;

        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }

    .toggle-button:focus{
        outline: none;
    }

    .toggle-button_line{
        width: 7vw;
        height: 3px;
        background: white;
    }

    /* CSS para menú mobile - SideDrawer */

    .side-drawer{
        height: 100%;
        background: #122E5F 0% 0% no-repeat padding-box;
        box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 72%;
        z-index: 200;
        -webkit-transform: translateX(-120%);
                transform: translateX(-120%);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }

    .side-drawer.open{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .side-drawer ul{
        height: 100%;
        list-style: none;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 0;
    }

    .side-drawer div{
        color: #FFFFFF;
        text-decoration: none;
        font-family: Roboto-Medium;
        font-size: 1.4rem;
    }

    .side-drawer a:hover,
    .side-drawer a:active{
        color: steelblue;
    }

    /* CSS para menú mobile - BackDrop */

    .backdrop{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 100;
    }

}
/* a partir de aquí BOTÓN AZUL FUERTE (por defecto): se expande al 140%*/

.formato-btngral-azul{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azul::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 16%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azul:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-azul p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL BRILLOSO */
.formato-btngral-azulbrilloso{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azulbrilloso::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #51B8B6;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: 13%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azulbrilloso:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-azulbrilloso p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL: se expande al 100% */

.formato-btngral-azul2{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azul2::after{
    content: '';
    width: 65%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 18%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azul2:hover::after{
    left: 0%;
    width: 100%;
}

.formato-btngral-azul2 p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL para asesoria personalizada */

.formato-btngral-asesoria{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-asesoria::after{
    content: '';
    width: 50%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 25%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-asesoria:hover::after{
    left: 10%;
    width: 80%;
}

.formato-btngral-asesoria p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL CIELO Enviar */

.formato-btngral-aqua{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-aqua::after{
    content: '';
    width: 200%;
    height: 0.7vw;
    background: #0078C0;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%; left: -50%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-aqua:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-aqua p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL CIELO Enviar: borde bottom inicial al 200% y se extrae al 75% */

.formato-btngral-azulEnviar{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azulEnviar::after{
    content: '';
    width: 200%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%; left: -50%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azulEnviar:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-azulEnviar p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL para Header: el borde aparece en el hover*/

.formato-btngral-header{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-header::after{
    content: '';
}

.formato-btngral-header:hover::after{
    height: 0.4vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%;
    left: -15%;
    width: 130%;
    border-radius: 10vw;
    transition: all 1000ms ease;
}

.formato-btngral-header p{
    margin-bottom: 0.5vw;
}

/* a partir de aquí BOTÓN AZUL para BannerCOVID*/

.formato-btngral-covid{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-covid::after{
    content: '';
    width: 160%;
    height: 0.7vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: -28%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-covid:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-covid p{
    margin-bottom: 0.4vw;
}

/* a partir de aquí BOTÓN AZUL para sección covid */

.formato-btngral-covid19{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-covid19::after{
    content: '';
    width: 120%;
    height: 0.95vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: -10%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-covid19:hover::after{
    left: 0%;
    width: 100%;
}

.formato-btngral-covid19 p{
    margin-bottom: 1.6vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .formato-btngral-azul::after{
        width: 110%;
        height: 0.4rem;
        bottom: 0%; left: -5%;
    }
    
    .formato-btngral-azul p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-azulEnviar::after{
        width: 150%;
        height: 0.4rem;
        bottom: 0%; left: -25%;
    }
    
    .formato-btngral-azulEnviar p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-azulbrilloso::after{
        width: 110%;
        height: 0.4rem;
        position: absolute;
        bottom: 0%; left: -5%;
    }
    
    .formato-btngral-azulbrilloso p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-aqua::after{
        width: 150%;
        height: 0.4rem;
        bottom: 0%; left: -25%;
    }
    
    .formato-btngral-aqua p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-asesoria::after{
        width: 100%;
        height: 0.6rem;
        bottom: 0%; left: 0%;
    }
    
    .formato-btngral-asesoria p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-covid19::after{
        height: 0.52rem;
        left: -8%;
        width: 116%;
    }
    
    .formato-btngral-covid19:hover::after{
        left: -8%;
        width: 116%;
    }
    
    .formato-btngral-covid19 p{
        margin-bottom: 1rem;
    }

}
.componentFooter-desk{
  display: inline;
}

.componentFooter-mobile{
  display: none;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100vw;
    text-align: center;
    padding: 1% 0;
    height: 8vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .cursor{
    cursor: pointer;
  }

  .box-footer{
    width: 100%;
  }

  .div-icons{
    height: 8vw;
    padding: 1% 8%;
    background: #122E5F 0% 0% no-repeat padding-box;
    border-top: 0.5vw solid #25C8F6;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .div-icon-menu-servicios{
    float: left;
  }

  .icon-menu-servicios{
    width: 2vw;
    max-height: 1vw;
    margin-right: 1vw;
    margin-bottom: 0.3vw;
  }

  .txt-servicios-footer{
    text-align: center;
    font-family: Roboto-Bold;
    font-size: 1.52vw;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
  }

  .icons-footer-efect{
    cursor: pointer;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    top:50%;
    left: 50%;
  }

  .icons-footer-efect:hover{
    -webkit-transform: translate(-50%,-50%) scale(1.25);
            transform: translate(-50%,-50%) scale(1.25);
    transition: all 500ms ease;
  }

  .div-txtfooter{
    font-size: 1.2vw;
    font-family: Roboto-Regular;
    color: #ffff;
  }

  .div-txtfooter p{
    margin: 0;
  }

  .div-txtfooter div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .div-servicios{
    width: 100%;
    height: 25vw;
    background: #122E5F 0% 0% no-repeat padding-box;
    box-shadow: 0vw 0.2vw 0.3vw #00000029;
    border-top: 0.05vw solid #ffff;
    color: #ffff;
    opacity: 1;
    padding: 3% 5%;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .div-servicios p{
    margin-bottom: 0;
    font-family: Roboto-Regular;
    font-size: 1.4vw;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .div-servicios > div > div{
    cursor: pointer;
    margin-bottom: 1vw;
    font-family: Roboto-Medium;
    font-size: 1.62vw;
    letter-spacing: 0px;
    opacity: 1;
  }

  .div-servicios > div > div:hover{
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    transition: all 150ms ease;
    margin-left: 0.8vw;
    border-bottom: 0.2vw solid #0078C1;
  }

  .divnfs {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .fs {
    width: 0.8vw;
    height: 1.8vw;
  }

@media screen and (max-width: 687px), (max-height: 325px) {
  .componentFooter-desk{
    display: none;
  }
  
  .componentFooter-mobile{
    display: inline;
  }

  .footer{
    height: 28vw;
  }

  .div-icons{
    height: 32vw;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    border-top: 1.5vw solid #25C8F6;
    padding: 4% 8% 5%;
  }

  .icon-menu-servicios{
    max-height: 3vw;
    width: 4vw;
    margin-right: 2.25vw;
  }

  .txt-servicios-footer{
    font-size: 3.5vw;
  }

  .div-txtfooter{
    font-size: 2vw;
    padding: 2% 0 1%;
  }

  .icons-footer-efect{
    cursor: pointer;
    -webkit-transform: translate(25%,-35%);
            transform: translate(25%,-35%);
    top:50%;
    left: 50%;
  }

  .icons-footer-efect:hover{
    -webkit-transform: translate(25%,-35%) scale(1.25);
            transform: translate(25%,-35%) scale(1.25);
    transition: all 500ms ease;
  }

  .div-servicios{
    height: 110vw;
    border-top: 0.1vw solid #ffff;
    padding: 6% 8%;
    text-align: left;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /*align-items: flex-start;*/
  }

  .div-servicios > div > div{
    font-size: 3.5vw;
  }

  .div-servicios p{
    font-size: 2.7vw;
  }

  .fs {
    width: 2.5vw;
    height: 3vw;
  }
}
.sectionHome-desk{
    display: inline;
}

.sectionHome-mobile{
    display: none;
}

.bienvenidahome{
    width: 100%;
    padding: 8% 1% 1% 6%;
}

.box-bienvenidahome{
    width: 100%;
    -webkit-align-content: space-evenly;
            align-content: space-evenly;
}

.txt-bienvenidahome{
    width: 92%;
}

.txt-vervideo-home{
    position: relative;
    width: 20%;
    left: 30vw;
    display: inline-block;
    text-align: right;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #1D3772;
    opacity: 1;
}

.icon-vervideo-home{
    position: absolute;
    margin-right: 1vw;
    width: 4vw;
    max-height: 4vw;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    top:50%;
    left: 45%;
}

.icon-vervideo-home:hover{
    -webkit-transform: translate(-50%,-50%) scale(1.25);
            transform: translate(-50%,-50%) scale(1.25);
    transition: all 500ms ease;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionHome-desk{
        display: none;
    }

    .sectionHome-mobile{
        display: inline;
    }

    .txt-bienvenidahome{
        width: 100%;
    }
}
.componentNuestrosClientes-desk{
    display: inline;
}

.componentNuestrosClientes-mobile{
    display: none;
}

.containerNuestrosClientes{
    width: 100%;
    height: 9vw;
    margin: 4% 0% 2% 0%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.05vw solid #DEDEDE;
    opacity: 1;
}

.div-tituloLogosClientes{
    position: relative;
    top: 1.5vw;
    width: 30%;
    float: left;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.div-logosClientes{
    position: relative;
    top: -0.4vw;
    width: 65%;
    display: inline-block;
}

.img-swiper-nc{
    width: 6vw;
}

.div-tituloLogosPartners{
    position: relative;
    top: 1.5vw;
    width: 28%;
    float: left;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.div-logosPartners{
    width: 65%;
    display: inline-block;
}

.img-swiper-np{
    width: 15vw;
    max-height: 5vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentNuestrosClientes-desk{
        display: none;
    }

    .componentNuestrosClientes-mobile{
        display: inline;
    }

    .containerNuestrosClientes{
        height: 20vw;
    }

    .div-tituloLogosClientes{
        font-size: 0.5rem;
        top: 2vw;
    }

    .div-tituloLogosPartners{
        font-size: 0.5rem;
        top: 2.5vw;
    }

    .img-swiper-nc{
        width: 8vw;
    }

    .img-swiper-np{
        width: 17vw;
        max-height: 6vw;
    }

}
.componentInscribeteBlog-desk{
    display: inline;
}

.componentInscribeteBlog-mobile{
    display: none;
}

.titulo-proyectosdestacados{
    margin-left: 14%;
    font-size: 1.8vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #00347D;
    opacity: 1;
}

.box-nuestroBlog{
    position: relative;
    width: 100vw;
}

.nuestroBlog{
    width: 100%;
    height: 25vw;
    background: transparent linear-gradient(360deg, #005EFC 0%, #25C8F6 100%) 0% 0% no-repeat padding-box;
    opacity: 0.15;
}

.container-elementos{
    position: absolute;
    width: 86%;
    top: 4vw;
    left: 6%;
}

.contacto-nuestroBlog{
    border-radius: 0.8vw;
    outline: none;
    width: 40%;
    border-width: thin;
    font-size: 1.32vw;
    margin-top: 1vw;
    padding: 1% 1.5%;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.18vw solid #0086FC;
}

.contacto-nuestroBlog::-webkit-input-placeholder{
    color: #707070;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
}

.contacto-nuestroBlog::-ms-input-placeholder{
    color: #707070;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
}

.contacto-nuestroBlog::placeholder{
    color: #707070;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
}

.div-botones{
    height: 10vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.num-tel{
    font-family: Roboto-Bold !important;
    color: #122E5F !important;
    margin-bottom: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentInscribeteBlog-desk{
        display: none;
    }

    .componentInscribeteBlog-mobile{
        display: inline;
    }

    .container-elementos{
        top: 6%;
    }

    .nuestroBlog{
        height: 64vw;
    }

    .div-botones{
        height: 25vw;
    }

    .contacto-nuestroBlog{
        width: 100%;
        font-size: 3.2vw;
        padding: 3% 3.5%;
        margin-top: 2vw;
        margin-bottom: 3vw;
    }

    .contacto-nuestroBlog::-webkit-input-placeholder{
        font-size: 3.2vw;
    }

    .contacto-nuestroBlog::-ms-input-placeholder{
        font-size: 3.2vw;
    }

    .contacto-nuestroBlog::placeholder{
        font-size: 3.2vw;
    }
}
.num-tel-btncontactanos{
    font-family: Roboto-Bold;
    color: #122E5F !important;
}

.icon-what-contactanos{
    position: absolute;
    top: -50%;
    width: 2vw;
}

/* a partir de aquí BOTÓN CONTACTANOS */
.formato-btngral-contactanos{
    cursor: pointer;
    font-family: Roboto-Regular;
    color: #0078C1;
    font-size: 1.83vw;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-contactanos::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #51B8B6;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: 13%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-contactanos:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-contactanos p{
    margin-bottom: 0.9vw;
}

.div-icon-what{
    position: absolute;
    top: -50%;
    right: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .formato-btngral-contactanos::after{
        height: 0.45rem;
        width: 100%;
        left: 0;
    }

    .formato-btngral-contactanos:hover::after{
        left: 0%;
        width: 100%;
    }
    .formato-btngral-contactanos{
        font-size: 0.83rem;
    }
    .formato-btngral-contactanos p{
        margin-bottom: 0.8rem;
    }

    .icon-what-contactanos{
        width: 5vw;
    }
}
.container-historiasexitoclientes{
    width: 100%;
}

.div-historiasexitoclientes{
    width: 30%;
    height: 28vw;
    border: 0.25vw solid #0078C1;
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 6px;
    opacity: 1;

    float: left;
    padding: 2% 2% 3%;
    margin-bottom: 3.4%;
    margin-right: 3%;
}

.logo-historiasexitoclientes{
    width: 4vw;
    display: block;
    margin-bottom: 10%;
}

.foto-historiasexitoclientes{
    width: 4vw;
    max-height: 50%;
    border-radius: 6px 6px 0 0;
    float: left;
}

.datoscliente-historiasexitoclientes{
    display: inline-block;
    width: 80%;
}

.nombre-historiasexitoclientes{
    margin-left: 8%;
    font-family: Roboto-Bold;
    font-size: 1.32vw;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
}

.cargoyempresa-historiasexitoclientes{
    margin-left: 8%;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.comentario-historiasexitoclientes{
    margin-top: 10%;
    font-family: Roboto-Regular;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.container-banner{
    position: relative;
    width: 100%;
    margin: 0 0 5vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.box-banner{
    width: 100%;
    height: 12vw;
    background: rgb(0,0,2);
    background: linear-gradient(90deg, rgba(0,0,2,1) 1%, rgba(0,45,74,1) 27%, rgba(0,43,68,1) 62%, rgba(0,0,2,1) 100%);
    box-shadow: 0.2vw 0.2vw 0.6vw #00000029;
    border-radius: 0.6vw;
    opacity: 1;
}

.espacio-bannerCOVID{
    display: inline-block;
    width: 35%;
    height: 100%;
    margin-left: 2%;
    padding: 3% 0;
}

.txt-banner{
    width: 60%;
    height: 100%;
    float: left;
    padding: 1% 2% 1% 4.5%;
    margin-bottom: 0;
    opacity: 1;
}

.txt-banner-b{
    font-size: 1.65vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: white;
}

.txt-banner-a{
    font-size: 1.8vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    color: #03FBFC;
    margin-bottom: 0;
}

.txt-aqui-banner{
    cursor: pointer;
    border-bottom-style: double;
    border-bottom-color: #1D3772;
    color: #1D3772 !important;
    font-family: Roboto-Black;
}

.img-swiper-covid{
    max-width: 15vw;
    max-height: 6.5vw;
}
.BannerActualizado_BannerDesk__1EtC6 {
 display: inline;
}

.BannerActualizado_BannerMob__whvPM {
    display: none;
}

.BannerActualizado_ContainerBanner__3NKGU {
    position: relative;
    width: 98%;
    height: 19vw;
    box-shadow: 0.65vw 0.65vw 0vw #0D1675;
    border: none;
    margin: 4% 0% 5% 0.2%;
}

.BannerActualizado_ContainerBanner__3NKGU img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .BannerActualizado_BannerDesk__1EtC6 {
        display: none;
    }
       
    .BannerActualizado_BannerMob__whvPM {
        display: inline;
    }

    .BannerActualizado_ContainerBanner__3NKGU {
        height: 43vw;
    }
}
.componentAnimServicios-desk{
    display: inline;
}

.componentAnimServicios-mobile{
    display: none;
}

.container-todoslosservicios{
    width: 100vw;
    height: 59vw;
    background: rgb(1,7,57);
    background: radial-gradient(circle, rgba(1,7,57,1) 1%, rgba(1,7,46,1) 46%, rgba(1,7,29,1) 100%);
}

.div-logobbp-animServices{
    position: relative;
    width: 100%;
    height: 3vw;
}

.logobbp-animServices{
    width: 7.5vw;
    margin-top: 2%;
    margin-left: 45%;
}

.div-imagenes{
    width: 100%;
    height: 49vw;
}

.txt-descripciondeservicio{
    position: absolute;
    font-size: 1vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    color: #ffff;
}

.img-fondo-circulo{
    position: relative;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    top: 42%;
    max-height: 5vw;
}

.circulo{
    cursor: pointer;
    position: absolute;
    float: left;
    width: 38%;
    height: 9.2vw;
    border-radius: 50% 50%;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 100%);
    opacity: 1;
    z-index: 1;
}

.vermas{
    width: 60%;
    text-align: center;
    display: inline-block;
    color: rgb(255, 255, 255);
    position: relative;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    left: 50%;
    font-size: 0.85vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
}

.circulo:hover{
    background-image: none;
}

.circulo:hover > .img-fondo-circulo{
    -webkit-transform: translate(-50%,-50%) scale(1.15);
            transform: translate(-50%,-50%) scale(1.15);
    transition: all 500ms ease;
}

.flechita{
    position: absolute;
    float: left;
    width: 26%;
    max-height: 5vw;
}

.div-boton{
    width: 100%;
    height: 8vw;
    padding: 2% 7% 2% 1%;
    text-align: center;
}

/* animación css */

.efaGep {
    color: #1D3772 !important;
    padding-left: 0.25vw !important;
}

.rotatemedcirculo {
    animation: rotate linear infinite; -webkit-animation: rotate linear infinite;
    transform-origin: 50% 50%; -webkit-transform-origin: 50% 50%;
}
  
@keyframes rotate 
{
    from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@-webkit-keyframes rotate
{
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}

.contenedorservicios{
    -webkit-animation: fadein ease-in normal ;
    animation: fadein ease-in normal ;
}

.flechitaAnim{
    -webkit-animation: fadein ease-in-out reverse infinite;
    animation: fadein ease-in-out reverse infinite;
}

@-webkit-keyframes fadein {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0.1; }
    to { opacity: 1; }
}

.txt-nombredeservicio{
    position: relative;
    font-size: 2.2vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: #00B6ED;
}

.glitch {
    color: #00B6ED;
    font-size: 2.2vw;
    position: relative;
    font-family: Roboto-Bold;
}

.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 3px;
    text-shadow: -1px 0 red;
    top: 0;
    color: #00B6ED;
    background: #01072E;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
}
    
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: 1px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: #00B6ED;
    background:#01072E;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}

@-webkit-keyframes noise-anim {
    0% { clip: rect(51px, 9999px, 28px, 0);}
    5% { clip: rect(70px, 9999px, 19px, 0);}
    10% { clip: rect(92px, 9999px, 13px, 0);}
    15% { clip: rect(85px, 9999px, 49px, 0);}
    20% { clip: rect(45px, 9999px, 56px, 0);}
    25% { clip: rect(26px, 9999px, 31px, 0);}
    30% { clip: rect(62px, 9999px, 73px, 0);}
    35% { clip: rect(34px, 9999px, 11px, 0);}
    40% { clip: rect(26px, 9999px, 61px, 0);}
    45% { clip: rect(34px, 9999px, 71px, 0);}
    50% { clip: rect(35px, 9999px, 69px, 0);}
    55% { clip: rect(92px, 9999px, 81px, 0);}
    60% { clip: rect(26px, 9999px, 85px, 0);}
    65% { clip: rect(2px, 9999px, 68px, 0);}
    70% { clip: rect(54px, 9999px, 79px, 0);}
    75% { clip: rect(35px, 9999px, 85px, 0);}
    80% {clip: rect(70px, 9999px, 60px, 0);}
    85% {clip: rect(32px, 9999px, 26px, 0);}
    90% {clip: rect(94px, 9999px, 53px, 0);}
    95% {clip: rect(99px, 9999px, 92px, 0);}
    100% {clip: rect(42px, 9999px, 27px, 0);}
}

@keyframes noise-anim {
    0% { clip: rect(51px, 9999px, 28px, 0);}
    5% { clip: rect(70px, 9999px, 19px, 0);}
    10% { clip: rect(92px, 9999px, 13px, 0);}
    15% { clip: rect(85px, 9999px, 49px, 0);}
    20% { clip: rect(45px, 9999px, 56px, 0);}
    25% { clip: rect(26px, 9999px, 31px, 0);}
    30% { clip: rect(62px, 9999px, 73px, 0);}
    35% { clip: rect(34px, 9999px, 11px, 0);}
    40% { clip: rect(26px, 9999px, 61px, 0);}
    45% { clip: rect(34px, 9999px, 71px, 0);}
    50% { clip: rect(35px, 9999px, 69px, 0);}
    55% { clip: rect(92px, 9999px, 81px, 0);}
    60% { clip: rect(26px, 9999px, 85px, 0);}
    65% { clip: rect(2px, 9999px, 68px, 0);}
    70% { clip: rect(54px, 9999px, 79px, 0);}
    75% { clip: rect(35px, 9999px, 85px, 0);}
    80% {clip: rect(70px, 9999px, 60px, 0);}
    85% {clip: rect(32px, 9999px, 26px, 0);}
    90% {clip: rect(94px, 9999px, 53px, 0);}
    95% {clip: rect(99px, 9999px, 92px, 0);}
    100% {clip: rect(42px, 9999px, 27px, 0);}
}

@-webkit-keyframes noise-anim-2 {
    0% {clip: rect(21px, 9999px, 49px, 0);}
    5% {clip: rect(27px, 9999px, 52px, 0);}
    10% {clip: rect(21px, 9999px, 70px, 0);}
    15% {clip: rect(65px, 9999px, 25px, 0);}
    20% {clip: rect(24px, 9999px, 10px, 0);}
    25% {clip: rect(61px, 9999px, 64px, 0);}
    30% {clip: rect(34px, 9999px, 47px, 0);}
    35% {clip: rect(46px, 9999px, 93px, 0);}
    40% {clip: rect(46px, 9999px, 9px, 0);}
    45% {clip: rect(67px, 9999px, 36px, 0);}
    50% {clip: rect(72px, 9999px, 20px, 0);}
    55% {clip: rect(79px, 9999px, 26px, 0);}
    60% {clip: rect(13px, 9999px, 87px, 0);}
    65% {clip: rect(10px, 9999px, 25px, 0);}
    70% {clip: rect(85px, 9999px, 11px, 0);}
    75% {clip: rect(94px, 9999px, 35px, 0);}
    80% {clip: rect(50px, 9999px, 86px, 0);}
    85% {clip: rect(74px, 9999px, 67px, 0);}
    90% {clip: rect(1px, 9999px, 40px, 0);}
    95% {clip: rect(3px, 9999px, 48px, 0);}
    100% {clip: rect(90px, 9999px, 23px, 0);}
}

@keyframes noise-anim-2 {
    0% {clip: rect(21px, 9999px, 49px, 0);}
    5% {clip: rect(27px, 9999px, 52px, 0);}
    10% {clip: rect(21px, 9999px, 70px, 0);}
    15% {clip: rect(65px, 9999px, 25px, 0);}
    20% {clip: rect(24px, 9999px, 10px, 0);}
    25% {clip: rect(61px, 9999px, 64px, 0);}
    30% {clip: rect(34px, 9999px, 47px, 0);}
    35% {clip: rect(46px, 9999px, 93px, 0);}
    40% {clip: rect(46px, 9999px, 9px, 0);}
    45% {clip: rect(67px, 9999px, 36px, 0);}
    50% {clip: rect(72px, 9999px, 20px, 0);}
    55% {clip: rect(79px, 9999px, 26px, 0);}
    60% {clip: rect(13px, 9999px, 87px, 0);}
    65% {clip: rect(10px, 9999px, 25px, 0);}
    70% {clip: rect(85px, 9999px, 11px, 0);}
    75% {clip: rect(94px, 9999px, 35px, 0);}
    80% {clip: rect(50px, 9999px, 86px, 0);}
    85% {clip: rect(74px, 9999px, 67px, 0);}
    90% {clip: rect(1px, 9999px, 40px, 0);}
    95% {clip: rect(3px, 9999px, 48px, 0);}
    100% {clip: rect(90px, 9999px, 23px, 0);}
}
    

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentAnimServicios-desk{
        display: none;
    }
    
    .componentAnimServicios-mobile{
        display: inline;
    }

    .container-todoslosservicios{
        height: 268vw;
    }

    .div-imagenes{
        height: 245vw;
    }

    .div-boton{
        height: 20vw;
    }

    .div-logobbp-animServices{
        height: 15vw;
    }

    .logobbp-animServices {
        width: 22vw;
        margin-top: 4%;
        margin-left: 40%;
    }
    
    .circulo{
        width: 54%;
        height: 26.2vw;
    }

    .img-fondo-circulo{
        top: 45%;
        max-height: 17vw;
    }

    .vermas{
        font-size: 0.69rem;
    }

    .glitch{
        font-size: 0.8rem;
    }

    .txt-descripciondeservicio{
        font-size: 0.5rem;
    }
    
    .circulo:hover{
        background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 100%);

    }
    
    .circulo:hover > .img-fondo-circulo{
        -webkit-transform: translate(-50%,-50%) scale(1.05);
                transform: translate(-50%,-50%) scale(1.05);
    }
}

.divTyped{
    position: relative;
    width: 100%;
    margin-bottom: 2.5vw;
    line-height: 1.2;
}

.typed-cursor{
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink{
    50% { opacity: 0.0; }
}

@-webkit-keyframes typedjsBlink{
    0% { opacity: 1; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}
  
.typed-fade-out{
    opacity: 0;
    transition: opacity .25s;
    -webkit-animation: 0;
    animation: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .divTyped{
        margin-bottom: 1.5rem;
        line-height: 1.2;
    }
}
.sectionServicios-desk{
    display: inline;
}

.sectionServicios-mobile{
    display: none;
}

.intro-servicios{
    width: 75%;
    display: inline-block;
}

.div-contenedor-agendarcita{
    position: relative;
    width: 18%;
    float: left;
    height: 85vw;
}

.contenedor-btngral-agendarcita-serv{
    position: absolute;
    top: 20%;
    left: -2%;
    text-align: center;
}

.boton-agendarcita{
    width: 100%;
    height: 12vw;
    top: 35%;
    background: transparent linear-gradient(90deg, #25C8F6 0%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: -3px 3px 6px #00000029;
    opacity: 0.5;
    border: none;
    border-radius: 0.3vw;
}

.boton-agendarcita p{
    width: 65%;
    text-align: center;
    margin-left: 14.5%;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionServicios-desk{
        display: none;
    }

    .sectionServicios-mobile{
        display: inline;
    }

    .intro-servicios{
        width: 100%;
    }

    .divBtnGral-mobile{
        position: relative;
        text-align: center;
        padding: 10% 20% 0;
        line-height: 1.25;
    }
}
.componentPotenciamosIdeas-desk{
    display: inline;
}

.componentPotenciamosIdeas-mobile{
    display: none;
}

.line-tarjetitas{
    display: inline-block;
    width: 40%;
    height: 36vw;
    margin-top: 3vw;
    margin-right: 7%;
    box-shadow: 0vw 0.3vw 0.6vw #00000029;
    border: 0.19vw solid #25C8F6;
    border-radius: 0.7vw;
    opacity: 1;
}

.back-foto-estrategia{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-estrategia::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #A4B2C100 0%, #96B2C400 9%, #74B3CD 34%, #25C8F6 55%, #25C8F6 82%, #25C8F6 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-arquitectura{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-arquitectura::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #000000D0 0%, #4C5252C4 18%, #005EFC 41%, #005EFC 73%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-software{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-software::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #A4B2C100 0%, #96B2C400 14%, #74B3CD 34%, #25C8F6 55%, #25C8F6 82%, #00B6ED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-nube{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-nube::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #505C5A00 0%, #005EFC 41%, #005EFC 62%, #005EFC 73%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.foto-tarjetitaspotideas{
    width: 100%;
    height: 100%;
    border-radius: 0.6vw 0.6vw 0 0;
}

.containertxt-tarjetitaspotideas{
    padding: 3% 5% 5%;
}

.div-logo-nombre-potideas{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 6%;
    width: 100%;
}

.logo-tarjetitaspotideas{
    width: 4vw;
    height: 4vw;
}

.nombre-tarjetitaspotideas{
    float: left;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #25C8F6;
    margin-left: 6%;
    opacity: 1;
}

.descripcion-tarjetitaspotideas{
    font-family: Roboto-Medium;
    min-height: 11vw;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #002C45;
    opacity: 1;
}

.div-sabermas-potideas{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

@media screen and (max-width: 687px), (max-height: 325px) {

    .componentPotenciamosIdeas-desk{
        display: none;
    }

    .componentPotenciamosIdeas-mobile{
        display: inline;
    }
    .line-tarjetitas{
        width: 94%;
        height: 40vw;
        margin-top: 3vw;
        margin-right: 0;

        border: none;
        border-radius: 0.8vw 0 0 0.8vw;
    }

    .back-foto-estrategia{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-arquitectura{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-software{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-nube{
        width: 60%;
        height: 100%;
        float: left;
    }

    .containertxt-tarjetitaspotideas{
        width: 40%;
        height: 100%;
        padding: 4% 3% 4%;
        float: left;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;

        box-shadow: 0vw 0.3vw 0.6vw #00000029;
        border: 0.1rem solid #25C8F6;
        border-left: none;
        border-radius: 0 0.8vw 0.8vw 0;
    }

    .nombre-tarjetitaspotideas{
        font-size: 1.2rem;
        margin-left: 0;
        line-height: 1.3;
    }

    .logo-tarjetitaspotideas {
        width: 13vw;
        height: 10vw;
    }

    .container-descripcionServicio-mobile{
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.85;
        border-radius: 0.8vw;
    }

    .descripcion-tarjetitaspotideas{
        position: absolute;
        width: 88%;
        padding: 5% 4%;
        font-size: 0.7rem;
        color: #25C8F6;
    }

    .div-sabermas-potideas{
        position: relative;
        right: 3%;
        top: 80%;
    }
}
.sectionEstrategia-desk{
    display: inline;
}

.sectionEstrategia-mob{
    display: none;
}

.box-servicios{
    width: 100%;

}

.div-izq-servicios{
    float: left;
    width: 50%;
}

.div-der-servicios{
    display: inline-block;
    width: 50%;
}

.txt-servicios{
    position: relative;
}

.img-principal{
    width: 37vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.img-descripcion{
    width: 26vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
    margin-left: 5vw;
}

.imagenfloat{
    width: 37vw;
    margin-left: 10%;
}

.servicios-titulo{
    letter-spacing: 0px;
    color: #1D3772;
    font-size: 4.5vw;
    font-family: Roboto-Regular;
    line-height: 1.2;
    opacity: 1;
}

.servicios-btn{
    width: 40%;
    border: none;
    border-radius: 6px;
    padding: 1.2%;
    color: white;
    background: rgb(0,204,240);
    background: linear-gradient(180deg, rgba(0,204,240,1) 0%, rgba(0,52,125,1) 88%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.contacto-servicios{
    border-radius: 0.5vw;
    outline: none;
    width: 82%;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    padding: 2% 3%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
}




.introContacto{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: regular;
    letter-spacing: 0px;
    color: #170A29;
    opacity: 1;
}




.div-contenedor-form{
    position: relative;
    width: 100%;
    height: 89vw;
}

.formContactanos{
    margin-left: 8%;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
}

.contenedor-btngral-agendarcita{
    position: relative;
    top: 20%;
    left: 6%;
}

.form-sticky{
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: 12vw;
    top: 26%;
    margin-bottom: 10vw;
    
}

.formulario{
    position: relative;
    top: -10vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionEstrategia-desk{
        display: none;
    }
    
    .sectionEstrategia-mob{
        display: inline;
    }

    .div-DetalleServiciosM{
        width: 100%;
    }

    .img-descriptionM{
        width: 100%;
        margin-top: 2vw;
        margin-bottom: 2.5vw;
    }

    .subtitle-sectionsM {
        width: 100%;
        font-size: 1rem;
        display: inline-block;
        font-family: Roboto-Bold;
        letter-spacing: 0px;
        color: rgb(18, 46, 95);
        opacity: 1;
    }

    .subtitle-sectionsM1 {
        width: 100%;
        display: inline-block;
        width: 85%;
        font-size: 1.5rem;
        font-family: Roboto-Bold;
        letter-spacing: 0px;
        color: #0078C1;
        opacity: 1;
    }

    .subtitle-sectionsM2 {
        display: inline-block;
        width: 100%;
        font-size: 1.2rem;
        font-family: Roboto-Bold;
        letter-spacing: 0px;
        color: rgb(18, 46, 95);
        opacity: 1;
        font-weight: bold;
    }

    .content-sectionsM{
        display: inline-block;
        width: 100%;
        font-size: 1rem;
        font-family: Roboto-Regular;
        letter-spacing: 0px;
        color: #122E5F;
        opacity: 1;
    }
}

.animsvg-root{
    display: inline-block;
    position: relative;
}
.animsvg-frame{
    display: inline-block;
    position: absolute;
    left: 0;
    top:0;
    transition: opacity 0.5s ease;
}
.form-desk{
    display: inline;
}

.form-mob{
    display: none;
}


.bttn_formRegistro_css {
    display: inline-block;
    border: none;
    outline: none;
    margin-top: 3%;
    height: 3vw;
    width: 20%;
    background: none;
    border: transparent solid 1px;
    border-bottom: none;
    position: relative;

    font-family: Roboto-Medium;
    font-size: 1.52vw;
    color: #1D3772;
}
.bttn_formRegistro_css:after {
    content:"";
    background: -webkit-linear-gradient(left, #25C8F6 0%, #0078C1 100%);
    border-radius: 6px;
    display: block;
    height: 0.6vw;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.bttn_formRegistro_css:focus{
    border: none;
    outline: none;
}

.bttn_formRegistro_css:active{
    border: none;
    outline: none;
}

.bttn_formRegistro_css:hover::after{
    transition: all ease-in-out 0.5s;
    left: 10%;
    width: 80%;
}

.separator-div{
    width: 100%;
    height: 4vw;
    display: inline-block;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .form-desk{
        display: none;
    }
    
    .form-mob{
        display: inline;
    }

    .contacto-servicios {
        border-radius: 0.5vw;
        outline: none;
        width: 82%;
        font-size: 1.1vw;
        margin-top: 1.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }

    .form-container{
        border: #0078C1;
        border-style: solid;
        padding: 0% 6% 0% 6%;
        border-radius: 2vw;
        height: 106%;
        margin-top: 8vw;
    }

    .subtitle-sectionsFM{
        width: 100%;
        font-size: 4.15vw;
        margin-top: 4vw;

    }

    .content-sectionsFM{
        width: 100%;
        font-size: 3.1vw;
    }

    .contacto-serviciosFM {
        border-radius: 1.5vw;
        outline: none;
        width: 100%;
        font-size: 3.1vw;
        margin-top: 3.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }


    .subtitle-sectionsFM{
        display: inline-block;
        width: 85%;
        font-size: 4vw;
        font-family: Roboto-Bold;
        letter-spacing: 0px;
        color: #0078C1;
        opacity: 1;
    }

    .bttn_formRegistro_css{
        width: 30%;
        height: 8vw;
        font-size: 4vw;
    }
}
.sectionArquitectura-desk{
    display: inline;
}

.sectionArquitectura-mob{
    display: none;
}


.box-servicios{
    width: 100%;

}

.div-izq-servicios{
    float: left;
    width: 50%;
}

.div-der-servicios{
    display: inline-block;
    width: 50%;
}

.txt-servicios{
    position: relative;
}

.img-descriptionArq{
   margin-left: 6vw;
    width: 29vw;
    margin-top: 4vw;
    margin-bottom: 2.5vw;
}
.img-description{
    width: 37vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.imagenfloat{
    width: 37vw;
    margin-left: 10%;
}

.formContactanos{
    margin-left: 8%;
}

.servicios-titulo{
    letter-spacing: 0px;
    color: #1D3772;
    font-size: 4.5vw;
    font-family: Roboto-Regular;
    line-height: 1.2;
    opacity: 1;
}

.servicios-btn{
    width: 40%;
    border: none;
    border-radius: 6px;
    padding: 1.2%;
    color: white;
    background: rgb(0,204,240);
    background: linear-gradient(180deg, rgba(0,204,240,1) 0%, rgba(0,52,125,1) 88%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.contacto-servicios{
    border-radius: 0.5vw;
    outline: none;
    width: 82%;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    padding: 2% 3%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
}




.introContacto{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: regular;
    letter-spacing: 0px;
    color: #170A29;
    opacity: 1;
}

.stickyform{
    position: fixed;
    top: 9vw;
}


@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionArquitectura-desk{
        display: none;
    }

    .sectionArquitectura-mob{
        display: inline;
    }

}
.softtwareExtensiones-desk{
    display: inline;
}

.softtwareExtensiones-mob{
    display: none;
}

.box-servicios{
    width: 100%;

}

.div-izq-servicios{
    float: left;
    width: 50%;
}

.div-der-servicios{
    display: inline-block;
    width: 50%;
}

.txt-servicios{
    position: relative;
}

.img-description{
    width: 37vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.img-descriptionSExt{
    width: 26vw;
    margin-top: 6vw;
    margin-bottom: 2.5vw;
    margin-left: 11vw;
}

.imagenfloat{
    width: 37vw;
    margin-left: 10%;
}

.formContactanos{
    margin-left: 8%;
}

.servicios-titulo{
    letter-spacing: 0px;
    color: #1D3772;
    font-size: 4.5vw;
    font-family: Roboto-Regular;
    line-height: 1.2;
    opacity: 1;
}

.servicios-btn{
    width: 40%;
    border: none;
    border-radius: 6px;
    padding: 1.2%;
    color: white;
    background: rgb(0,204,240);
    background: linear-gradient(180deg, rgba(0,204,240,1) 0%, rgba(0,52,125,1) 88%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.contacto-servicios{
    border-radius: 0.5vw;
    outline: none;
    width: 82%;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    padding: 2% 3%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
}




.introContacto{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: regular;
    letter-spacing: 0px;
    color: #170A29;
    opacity: 1;
}

.stickyform{
    position: fixed;
    top: 9vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .softtwareExtensiones-desk{
        display: none;
    }

    .softtwareExtensiones-mob{
        display: inline;
    }

}

.serviciosNube-desk{
    display: inline;
}

.serviciosNube-mob{
    display: none;
}


.box-servicios{
    width: 100%;

}

.div-izq-servicios{
    float: left;
    width: 50%;
}

.div-der-servicios{
    display: inline-block;
    width: 50%;
}

.txt-servicios{
    position: relative;
}

.img-description{
    width: 37vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.img-descriptionNube{
    width: 24vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
    margin-left: 8vw;
}




.imagenfloat{
    width: 37vw;
    margin-left: 10%;
}

.formContactanos{
    margin-left: 8%;
}

.servicios-titulo{
    letter-spacing: 0px;
    color: #1D3772;
    font-size: 4.5vw;
    font-family: Roboto-Regular;
    line-height: 1.2;
    opacity: 1;
}

.servicios-btn{
    width: 40%;
    border: none;
    border-radius: 6px;
    padding: 1.2%;
    color: white;
    background: rgb(0,204,240);
    background: linear-gradient(180deg, rgba(0,204,240,1) 0%, rgba(0,52,125,1) 88%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.contacto-servicios{
    border-radius: 0.5vw;
    outline: none;
    width: 82%;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    padding: 2% 3%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
}




.introContacto{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: regular;
    letter-spacing: 0px;
    color: #170A29;
    opacity: 1;
}

.stickyform{
    position: fixed;
    top: 9vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .serviciosNube-desk{
        display: none;
    }

    .serviciosNube-mob{
        display: inline;
    }

}

.sectionContacto-desk{
    display: inline;
}

.sectionContacto-mob{
    display: none;
}


.contacto-container{
    width: 100%;
    padding: 8% 1% 1% 5%;
}


.titulo-proyectosdestacados{
    margin-left: 14%;
    font-size: 1.8vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #00347D;
    opacity: 1;
}

.contacto{
    margin-right: 8px;
    border-width: thin;
    font-size: 15px;
    margin-top: 10px;
    border-color: #707070;
    border-radius: 4px;  
}

.btn-enviar {
    background: none;
    border-color: transparent transparent coral transparent;
    border-radius: 5px;
    border-bottom: 2px solid #00347D;
    border-width: thick;
    font-size: 19px;
    width: 9%;
    }


.container-ubicacion{
    display: block;
    margin-top: 6%;
}

.llamanos{    
    /* display: block; */
    width: 50%;
    padding: 2vw 6vw 2vw 4vw;
    position: relative;
    /* top: 89vw;
    left: 45vw; */
}
.btn-llamanos{
    background: none;
    border-color: transparent transparent coral transparent;
    border-radius: 5px;
    border-bottom: 2px solid #398b1f;
    border-width: thick;
    font-size: 19px;
    width: 58%;
    margin-left: 15%;
}

.titulo-contactanos{
    font-size: 6.58vw;
    font-family: Roboto-Bold;
    margin-bottom: 2.5vw;
    line-height: 1.05;
    letter-spacing: 0px;
    color: #1D3772; 
}


.ubicacion-map{
    width: 50%;
}

.mapa{
    width: 100%;
    height: 100%;
}

.direccion-txt{
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    font-size: 1.5vw;
}

.btn-llamar{    
    text-align: center;
    padding: 12% 5% 5%;
}

.contacto-formulario{
    border-radius: 0.5vw;
    outline: none;
    width: 27%;
    font-size: 1.1vw;
    margin-top: 2.5vw;
    padding: 1% 1%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
    margin-right: 1vw;
    
}

.info-container{
    display: -webkit-flex;
    display: flex;
    height: 25vw;
    margin-bottom: 6vw;
}

.logo-bbpmap{
    margin-left: 1vw;
    margin-bottom: 2vw;
}

.atencion{
    font-family: Roboto-Regular;
    font-weight: bold;
    color: #25C8F6;
}


@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionContacto-desk{
        display: none;
    }

    .sectionContacto-mob{
        display: inline;
    }


    .contacto-formulario {
        border-radius: 2.5vw;
        outline: none;
        width: 100%;
        font-size: 4.1vw;
        margin-top: 5.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
        margin-right: 1vw;
    }

    .contacto-containerMob {
        padding: 8% 10% 1% 5%;
    }

    .textarea-servicios {
        width: 100%;
        border-radius: 1.5vw;
        outline: none;
        font-size: 4.1vw;
        margin-top: 5.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }

    .panel{
        display: block;
        text-align: center;

    }

    .ubicacion-map {
        width: 100%;
        height: 57vw;
    }

    .llamanos {
        /* display: block; */
        width: 100%;
        padding: 2vw 6vw 2vw 4vw;
        position: relative;
        margin-top: 4vw;
    }

    .direccion-txt {       
        -webkit-align-items: center;       
                align-items: center;
        text-align: center;
        font-size: 3.5vw;
    }

    .info-containerM {
        display: -webkit-flex;
        display: flex;
        /* height: 25vw; */
        margin-bottom: 6vw;
    }

}
.intro-proyectos{
    width: 100%;
}

.proyectosBanner-nuestrospartners{
    width: 100%;
    height: 22vw;
    padding: 3% 7%;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.div-imagenes-proyectosBanner{
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-top: 0.8vw;
    width: 60%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.div-imagenes-proyectosBanner img{
    width: 14vw;
    max-height: 5vw;
}
.container-tarjetitasproyectos{
    width: 100%;
}

.div-tarjetitasproyectos{
    width: 30%;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-bottom: 3.4%;
    margin-right: 3.33%;
}
.root-service-card{
    border-radius: 1vw;
    width: 100%;
    float: left;
    background-color: white;
    border: 0.14vw solid #0086FC;
    box-shadow: 0vw 0.3vw 0.6vw #00000029;
}

.img-service-card{
    width: 100%;
    height: 12vw;
    object-fit: cover;
    border-top-left-radius: 0.8vw;
    border-top-right-radius: 0.8vw;
}

.logo-empresa{
    float: left;
    height: 2.1vw;
}

.title-service-card{
    width: 92%;
    float: left;
    font-family: Roboto-Bold;
    font-size: 1.5vw;
    color: #0078C1;
}

.signo-service-card{
    width: 8%;
    height: 2.25vw;
    float: left;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.content-service-card{
    padding: 1.3vw;
}

.description-service-card{
    width: 100%;
    height: 0;
    font-size: 0.9vw;
    font-family: Roboto-Medium;
    overflow: hidden;
    color: #122E5F;
    transition: height 0.3s ease;
    padding-top: 1vw;
    line-height: 2vw;
}

.container-img-service{
    width: 100%;
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
}
.sectionNosotros-mobile{
    display: none;
}

.sectionNosotros-desk{
    display: inline;
}

.intro-acercade{
    width: 100%;
}

.acercade-imgtitulo{
    display: inline-block;
    width: 85%;
    margin-bottom: 5vw;
}

.content-acercade{
    width: 100%;
    padding: 2% 0 8%;
}


.txtpadding-contentimg{
    padding: 0 3% 0 0;
}

.historiasdeexito-acercade{
    width: 100%;
    padding-bottom: 1%;
}

.nuestrafilosofia-acercade{
    width: 100%;
    padding: 3% 0% 8%;
}

.container-equipodelideres{
    position: relative;
    width: 62%;
    height: 27vw;
    margin-top: 5vw;
    float: left;
}

.container-equipoliderazgo-class{
    width: 33%;
    height: 28vw;
    text-align: center;
    float: left;
}

.foto-lideres{
    position: absolute;
    object-fit: contain;
    object-position: center;
    width: 15vw;
    height: 15vw;
    border-radius: 50% 50%;
}

.contorno-foto{
    width: 15vw;
    position: relative;
    height: 15vw;
}

.nombrelider{
    font-family: Roboto-Medium;
    font-size: 1vw;
    margin-top: 2vw;
    letter-spacing: 0px;
    color: #0086FC;
    opacity: 1;
}

.cargolider{
    font-family: Roboto-Medium;
    font-size: 0.9vw;
    letter-spacing: 0px;
    color: #25C8F6;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 0;
}

.frase-equipoliderazgo{
    font-family: JustTellMeWhat-Italic;
    font-size: 3.2vw;
    letter-spacing: 0px;
    line-height: 1.25;
    color: #25C8F6;
    opacity: 1;
}

.div-fraselideres{
    float: left;
    width: 30%;
    height: 27vw;
    position: relative;
    padding: 6% 0;
    margin-left: 6%;
}

.divpantalla-michamicha{
    width: 100%;
}

.micha-pantalla{
    width: 50%;
    float: left;
}

.icons-subsections{
    width: 4vw;
    margin-right: 5%;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionNosotros-mobile{
        display: inline;
    }

    .acercade-imgtitulo{
        margin-bottom: 10vw;
    }

    .sectionNosotros-desk{
        display: none;
    }

    .micha-pantalla{
        width: 100%;
    }

    .icons-subsections{
        width: 15vw;
    }

    .container-equipodelideres{
        width: 100%;
        height: 72vw;
        margin-top: 10vw;
    }

    .container-equipodelideres .swiper-pagination-bullet{
        width: 13px;
        height: 13px;
        background: white !important;
        border: 0.1rem solid #122E5F !important;
        opacity: 1 !important;
    }

    .container-equipodelideres .swiper-pagination-bullet-active {
        opacity: 1 !important;
        background: #122E5F !important;
    }

    .container-equipoliderazgo-class{
        width: 48%;
        height: 72vw;
    }

    .div-fraselideres{
        height: 45vw;
        width: 46%;
        padding: 10% 0 6%;
    }

    .foto-lideres,
    .contorno-foto {
        width: 40vw;
        height: 40vw;
    }

    .nombrelider{
        font-size: 0.7rem;
        margin-bottom: 0.2rem;
    }

    .cargolider{
        font-size: 0.6rem;
    }

    .frase-equipoliderazgo{
        width: 70%;
        font-size: 5vw;
    }

    .micha-pantalla p{
        margin-bottom: 1.6rem;
    }

}
.intro-comunidad{
    width: 100%;
}
