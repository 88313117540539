.sectionNosotros-mobile{
    display: none;
}

.sectionNosotros-desk{
    display: inline;
}

.intro-acercade{
    width: 100%;
}

.acercade-imgtitulo{
    display: inline-block;
    width: 85%;
    margin-bottom: 5vw;
}

.content-acercade{
    width: 100%;
    padding: 2% 0 8%;
}


.txtpadding-contentimg{
    padding: 0 3% 0 0;
}

.historiasdeexito-acercade{
    width: 100%;
    padding-bottom: 1%;
}

.nuestrafilosofia-acercade{
    width: 100%;
    padding: 3% 0% 8%;
}

.container-equipodelideres{
    position: relative;
    width: 62%;
    height: 27vw;
    margin-top: 5vw;
    float: left;
}

.container-equipoliderazgo-class{
    width: 33%;
    height: 28vw;
    text-align: center;
    float: left;
}

.foto-lideres{
    position: absolute;
    object-fit: contain;
    object-position: center;
    width: 15vw;
    height: 15vw;
    border-radius: 50% 50%;
}

.contorno-foto{
    width: 15vw;
    position: relative;
    height: 15vw;
}

.nombrelider{
    font-family: Roboto-Medium;
    font-size: 1vw;
    margin-top: 2vw;
    letter-spacing: 0px;
    color: #0086FC;
    opacity: 1;
}

.cargolider{
    font-family: Roboto-Medium;
    font-size: 0.9vw;
    letter-spacing: 0px;
    color: #25C8F6;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 0;
}

.frase-equipoliderazgo{
    font-family: JustTellMeWhat-Italic;
    font-size: 3.2vw;
    letter-spacing: 0px;
    line-height: 1.25;
    color: #25C8F6;
    opacity: 1;
}

.div-fraselideres{
    float: left;
    width: 30%;
    height: 27vw;
    position: relative;
    padding: 6% 0;
    margin-left: 6%;
}

.divpantalla-michamicha{
    width: 100%;
}

.micha-pantalla{
    width: 50%;
    float: left;
}

.icons-subsections{
    width: 4vw;
    margin-right: 5%;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionNosotros-mobile{
        display: inline;
    }

    .acercade-imgtitulo{
        margin-bottom: 10vw;
    }

    .sectionNosotros-desk{
        display: none;
    }

    .micha-pantalla{
        width: 100%;
    }

    .icons-subsections{
        width: 15vw;
    }

    .container-equipodelideres{
        width: 100%;
        height: 72vw;
        margin-top: 10vw;
    }

    .container-equipodelideres .swiper-pagination-bullet{
        width: 13px;
        height: 13px;
        background: white !important;
        border: 0.1rem solid #122E5F !important;
        opacity: 1 !important;
    }

    .container-equipodelideres .swiper-pagination-bullet-active {
        opacity: 1 !important;
        background: #122E5F !important;
    }

    .container-equipoliderazgo-class{
        width: 48%;
        height: 72vw;
    }

    .div-fraselideres{
        height: 45vw;
        width: 46%;
        padding: 10% 0 6%;
    }

    .foto-lideres,
    .contorno-foto {
        width: 40vw;
        height: 40vw;
    }

    .nombrelider{
        font-size: 0.7rem;
        margin-bottom: 0.2rem;
    }

    .cargolider{
        font-size: 0.6rem;
    }

    .frase-equipoliderazgo{
        width: 70%;
        font-size: 5vw;
    }

    .micha-pantalla p{
        margin-bottom: 1.6rem;
    }

}