.sectionServicios-desk{
    display: inline;
}

.sectionServicios-mobile{
    display: none;
}

.intro-servicios{
    width: 75%;
    display: inline-block;
}

.div-contenedor-agendarcita{
    position: relative;
    width: 18%;
    float: left;
    height: 85vw;
}

.contenedor-btngral-agendarcita-serv{
    position: absolute;
    top: 20%;
    left: -2%;
    text-align: center;
}

.boton-agendarcita{
    width: 100%;
    height: 12vw;
    top: 35%;
    background: transparent linear-gradient(90deg, #25C8F6 0%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: -3px 3px 6px #00000029;
    opacity: 0.5;
    border: none;
    border-radius: 0.3vw;
}

.boton-agendarcita p{
    width: 65%;
    text-align: center;
    margin-left: 14.5%;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionServicios-desk{
        display: none;
    }

    .sectionServicios-mobile{
        display: inline;
    }

    .intro-servicios{
        width: 100%;
    }

    .divBtnGral-mobile{
        position: relative;
        text-align: center;
        padding: 10% 20% 0;
        line-height: 1.25;
    }
}