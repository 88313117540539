.root-service-card{
    border-radius: 1vw;
    width: 100%;
    float: left;
    background-color: white;
    border: 0.14vw solid #0086FC;
    box-shadow: 0vw 0.3vw 0.6vw #00000029;
}

.img-service-card{
    width: 100%;
    height: 12vw;
    object-fit: cover;
    border-top-left-radius: 0.8vw;
    border-top-right-radius: 0.8vw;
}

.logo-empresa{
    float: left;
    height: 2.1vw;
}

.title-service-card{
    width: 92%;
    float: left;
    font-family: Roboto-Bold;
    font-size: 1.5vw;
    color: #0078C1;
}

.signo-service-card{
    width: 8%;
    height: 2.25vw;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-service-card{
    padding: 1.3vw;
}

.description-service-card{
    width: 100%;
    height: 0;
    font-size: 0.9vw;
    font-family: Roboto-Medium;
    overflow: hidden;
    color: #122E5F;
    transition: height 0.3s ease;
    padding-top: 1vw;
    line-height: 2vw;
}

.container-img-service{
    width: 100%;
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
}