.divTyped{
    position: relative;
    width: 100%;
    margin-bottom: 2.5vw;
    line-height: 1.2;
}

.typed-cursor{
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink{
    50% { opacity: 0.0; }
}

@-webkit-keyframes typedjsBlink{
    0% { opacity: 1; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}
  
.typed-fade-out{
    opacity: 0;
    transition: opacity .25s;
    -webkit-animation: 0;
    animation: 0;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .divTyped{
        margin-bottom: 1.5rem;
        line-height: 1.2;
    }
}