.sectionArquitectura-desk{
    display: inline;
}

.sectionArquitectura-mob{
    display: none;
}


.box-servicios{
    width: 100%;

}

.div-izq-servicios{
    float: left;
    width: 50%;
}

.div-der-servicios{
    display: inline-block;
    width: 50%;
}

.txt-servicios{
    position: relative;
}

.img-descriptionArq{
   margin-left: 6vw;
    width: 29vw;
    margin-top: 4vw;
    margin-bottom: 2.5vw;
}
.img-description{
    width: 37vw;
    margin-top: 2vw;
    margin-bottom: 2.5vw;
}

.imagenfloat{
    width: 37vw;
    margin-left: 10%;
}

.formContactanos{
    margin-left: 8%;
}

.servicios-titulo{
    letter-spacing: 0px;
    color: #1D3772;
    font-size: 4.5vw;
    font-family: Roboto-Regular;
    line-height: 1.2;
    opacity: 1;
}

.servicios-btn{
    width: 40%;
    border: none;
    border-radius: 6px;
    padding: 1.2%;
    color: white;
    background: rgb(0,204,240);
    background: linear-gradient(180deg, rgba(0,204,240,1) 0%, rgba(0,52,125,1) 88%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacto-servicios{
    border-radius: 0.5vw;
    outline: none;
    width: 82%;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    padding: 2% 3%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
}




.introContacto{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: regular;
    letter-spacing: 0px;
    color: #170A29;
    opacity: 1;
}

.stickyform{
    position: fixed;
    top: 9vw;
}


@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionArquitectura-desk{
        display: none;
    }

    .sectionArquitectura-mob{
        display: inline;
    }

}