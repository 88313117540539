.componentNuestrosClientes-desk{
    display: inline;
}

.componentNuestrosClientes-mobile{
    display: none;
}

.containerNuestrosClientes{
    width: 100%;
    height: 9vw;
    margin: 4% 0% 2% 0%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.05vw solid #DEDEDE;
    opacity: 1;
}

.div-tituloLogosClientes{
    position: relative;
    top: 1.5vw;
    width: 30%;
    float: left;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.div-logosClientes{
    position: relative;
    top: -0.4vw;
    width: 65%;
    display: inline-block;
}

.img-swiper-nc{
    width: 6vw;
}

.div-tituloLogosPartners{
    position: relative;
    top: 1.5vw;
    width: 28%;
    float: left;
    text-align: center;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.div-logosPartners{
    width: 65%;
    display: inline-block;
}

.img-swiper-np{
    width: 15vw;
    max-height: 5vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .componentNuestrosClientes-desk{
        display: none;
    }

    .componentNuestrosClientes-mobile{
        display: inline;
    }

    .containerNuestrosClientes{
        height: 20vw;
    }

    .div-tituloLogosClientes{
        font-size: 0.5rem;
        top: 2vw;
    }

    .div-tituloLogosPartners{
        font-size: 0.5rem;
        top: 2.5vw;
    }

    .img-swiper-nc{
        width: 8vw;
    }

    .img-swiper-np{
        width: 17vw;
        max-height: 6vw;
    }

}