.componentPotenciamosIdeas-desk{
    display: inline;
}

.componentPotenciamosIdeas-mobile{
    display: none;
}

.line-tarjetitas{
    display: inline-block;
    width: 40%;
    height: 36vw;
    margin-top: 3vw;
    margin-right: 7%;
    box-shadow: 0vw 0.3vw 0.6vw #00000029;
    border: 0.19vw solid #25C8F6;
    border-radius: 0.7vw;
    opacity: 1;
}

.back-foto-estrategia{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-estrategia::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #A4B2C100 0%, #96B2C400 9%, #74B3CD 34%, #25C8F6 55%, #25C8F6 82%, #25C8F6 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-arquitectura{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-arquitectura::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #000000D0 0%, #4C5252C4 18%, #005EFC 41%, #005EFC 73%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-software{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-software::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #A4B2C100 0%, #96B2C400 14%, #74B3CD 34%, #25C8F6 55%, #25C8F6 82%, #00B6ED 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.back-foto-nube{
    position: relative;
    width: 100%;
    height: 40%;
    border-radius: 0.2vw 0.2vw 0 0;
    display: block;
}

.back-foto-nube::after {
    content:"";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #505C5A00 0%, #005EFC 41%, #005EFC 62%, #005EFC 73%, #005EFC 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 0.5;
}

.foto-tarjetitaspotideas{
    width: 100%;
    height: 100%;
    border-radius: 0.6vw 0.6vw 0 0;
}

.containertxt-tarjetitaspotideas{
    padding: 3% 5% 5%;
}

.div-logo-nombre-potideas{
    display: inline-flex;
    align-items: center;
    margin-bottom: 6%;
    width: 100%;
}

.logo-tarjetitaspotideas{
    width: 4vw;
    height: 4vw;
}

.nombre-tarjetitaspotideas{
    float: left;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #25C8F6;
    margin-left: 6%;
    opacity: 1;
}

.descripcion-tarjetitaspotideas{
    font-family: Roboto-Medium;
    min-height: 11vw;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #002C45;
    opacity: 1;
}

.div-sabermas-potideas{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 687px), (max-height: 325px) {

    .componentPotenciamosIdeas-desk{
        display: none;
    }

    .componentPotenciamosIdeas-mobile{
        display: inline;
    }
    .line-tarjetitas{
        width: 94%;
        height: 40vw;
        margin-top: 3vw;
        margin-right: 0;

        border: none;
        border-radius: 0.8vw 0 0 0.8vw;
    }

    .back-foto-estrategia{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-arquitectura{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-software{
        width: 60%;
        height: 100%;
        float: left;
    }

    .back-foto-nube{
        width: 60%;
        height: 100%;
        float: left;
    }

    .containertxt-tarjetitaspotideas{
        width: 40%;
        height: 100%;
        padding: 4% 3% 4%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        box-shadow: 0vw 0.3vw 0.6vw #00000029;
        border: 0.1rem solid #25C8F6;
        border-left: none;
        border-radius: 0 0.8vw 0.8vw 0;
    }

    .nombre-tarjetitaspotideas{
        font-size: 1.2rem;
        margin-left: 0;
        line-height: 1.3;
    }

    .logo-tarjetitaspotideas {
        width: 13vw;
        height: 10vw;
    }

    .container-descripcionServicio-mobile{
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.85;
        border-radius: 0.8vw;
    }

    .descripcion-tarjetitaspotideas{
        position: absolute;
        width: 88%;
        padding: 5% 4%;
        font-size: 0.7rem;
        color: #25C8F6;
    }

    .div-sabermas-potideas{
        position: relative;
        right: 3%;
        top: 80%;
    }
}