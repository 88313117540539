.container-banner{
    position: relative;
    width: 100%;
    margin: 0 0 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-banner{
    width: 100%;
    height: 12vw;
    background: rgb(0,0,2);
    background: linear-gradient(90deg, rgba(0,0,2,1) 1%, rgba(0,45,74,1) 27%, rgba(0,43,68,1) 62%, rgba(0,0,2,1) 100%);
    box-shadow: 0.2vw 0.2vw 0.6vw #00000029;
    border-radius: 0.6vw;
    opacity: 1;
}

.espacio-bannerCOVID{
    display: inline-block;
    width: 35%;
    height: 100%;
    margin-left: 2%;
    padding: 3% 0;
}

.txt-banner{
    width: 60%;
    height: 100%;
    float: left;
    padding: 1% 2% 1% 4.5%;
    margin-bottom: 0;
    opacity: 1;
}

.txt-banner-b{
    font-size: 1.65vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: white;
}

.txt-banner-a{
    font-size: 1.8vw;
    font-family: Roboto-Bold;
    letter-spacing: 0px;
    color: #03FBFC;
    margin-bottom: 0;
}

.txt-aqui-banner{
    cursor: pointer;
    border-bottom-style: double;
    border-bottom-color: #1D3772;
    color: #1D3772 !important;
    font-family: Roboto-Black;
}

.img-swiper-covid{
    max-width: 15vw;
    max-height: 6.5vw;
}