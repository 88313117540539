.intro-proyectos{
    width: 100%;
}

.proyectosBanner-nuestrospartners{
    width: 100%;
    height: 22vw;
    padding: 3% 7%;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.div-imagenes-proyectosBanner{
    display: inline-flex;
    margin-top: 0.8vw;
    width: 60%;
    justify-content: space-between;
}

.div-imagenes-proyectosBanner img{
    width: 14vw;
    max-height: 5vw;
}