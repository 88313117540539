.sectionContacto-desk{
    display: inline;
}

.sectionContacto-mob{
    display: none;
}


.contacto-container{
    width: 100%;
    padding: 8% 1% 1% 5%;
}


.titulo-proyectosdestacados{
    margin-left: 14%;
    font-size: 1.8vw;
    font-family: Roboto-Medium;
    letter-spacing: 0px;
    color: #00347D;
    opacity: 1;
}

.contacto{
    margin-right: 8px;
    border-width: thin;
    font-size: 15px;
    margin-top: 10px;
    border-color: #707070;
    border-radius: 4px;  
}

.btn-enviar {
    background: none;
    border-color: transparent transparent coral transparent;
    border-radius: 5px;
    border-bottom: 2px solid #00347D;
    border-width: thick;
    font-size: 19px;
    width: 9%;
    }


.container-ubicacion{
    display: block;
    margin-top: 6%;
}

.llamanos{    
    /* display: block; */
    width: 50%;
    padding: 2vw 6vw 2vw 4vw;
    position: relative;
    /* top: 89vw;
    left: 45vw; */
}
.btn-llamanos{
    background: none;
    border-color: transparent transparent coral transparent;
    border-radius: 5px;
    border-bottom: 2px solid #398b1f;
    border-width: thick;
    font-size: 19px;
    width: 58%;
    margin-left: 15%;
}

.titulo-contactanos{
    font-size: 6.58vw;
    font-family: Roboto-Bold;
    margin-bottom: 2.5vw;
    line-height: 1.05;
    letter-spacing: 0px;
    color: #1D3772; 
}


.ubicacion-map{
    width: 50%;
}

.mapa{
    width: 100%;
    height: 100%;
}

.direccion-txt{
    align-items: center;
    text-align: left;
    font-size: 1.5vw;
}

.btn-llamar{    
    text-align: center;
    padding: 12% 5% 5%;
}

.contacto-formulario{
    border-radius: 0.5vw;
    outline: none;
    width: 27%;
    font-size: 1.1vw;
    margin-top: 2.5vw;
    padding: 1% 1%;
    border: solid #0086FC;
    border-width: 1.5px 1.5px 1.5px 1.5px;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Roboto-Regular;
    margin-right: 1vw;
    
}

.info-container{
    display: flex;
    height: 25vw;
    margin-bottom: 6vw;
}

.logo-bbpmap{
    margin-left: 1vw;
    margin-bottom: 2vw;
}

.atencion{
    font-family: Roboto-Regular;
    font-weight: bold;
    color: #25C8F6;
}


@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionContacto-desk{
        display: none;
    }

    .sectionContacto-mob{
        display: inline;
    }


    .contacto-formulario {
        border-radius: 2.5vw;
        outline: none;
        width: 100%;
        font-size: 4.1vw;
        margin-top: 5.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
        margin-right: 1vw;
    }

    .contacto-containerMob {
        padding: 8% 10% 1% 5%;
    }

    .textarea-servicios {
        width: 100%;
        border-radius: 1.5vw;
        outline: none;
        font-size: 4.1vw;
        margin-top: 5.5vw;
        padding: 2% 3%;
        border: solid #0086FC;
        border-width: 1.5px 1.5px 1.5px 1.5px;
        box-shadow: 0px 3px 6px #00000029;
        font-family: Roboto-Regular;
    }

    .panel{
        display: block;
        text-align: center;

    }

    .ubicacion-map {
        width: 100%;
        height: 57vw;
    }

    .llamanos {
        /* display: block; */
        width: 100%;
        padding: 2vw 6vw 2vw 4vw;
        position: relative;
        margin-top: 4vw;
    }

    .direccion-txt {       
        align-items: center;
        text-align: center;
        font-size: 3.5vw;
    }

    .info-containerM {
        display: flex;
        /* height: 25vw; */
        margin-bottom: 6vw;
    }

}