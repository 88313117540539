/* a partir de aquí BOTÓN AZUL FUERTE (por defecto): se expande al 140%*/

.formato-btngral-azul{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azul::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 16%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azul:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-azul p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL BRILLOSO */
.formato-btngral-azulbrilloso{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azulbrilloso::after{
    content: '';
    width: 75%;
    height: 0.6vw;
    background: #51B8B6;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: 13%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azulbrilloso:hover::after{
    left: -20%;
    width: 140%;
}

.formato-btngral-azulbrilloso p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL: se expande al 100% */

.formato-btngral-azul2{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azul2::after{
    content: '';
    width: 65%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 18%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azul2:hover::after{
    left: 0%;
    width: 100%;
}

.formato-btngral-azul2 p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL para asesoria personalizada */

.formato-btngral-asesoria{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-asesoria::after{
    content: '';
    width: 50%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: 25%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-asesoria:hover::after{
    left: 10%;
    width: 80%;
}

.formato-btngral-asesoria p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL CIELO Enviar */

.formato-btngral-aqua{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-aqua::after{
    content: '';
    width: 200%;
    height: 0.7vw;
    background: #0078C0;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%; left: -50%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-aqua:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-aqua p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL CIELO Enviar: borde bottom inicial al 200% y se extrae al 75% */

.formato-btngral-azulEnviar{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-azulEnviar::after{
    content: '';
    width: 200%;
    height: 0.6vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%; left: -50%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-azulEnviar:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-azulEnviar p{
    margin-bottom: 0.9vw;
}

/* a partir de aquí BOTÓN AZUL para Header: el borde aparece en el hover*/

.formato-btngral-header{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-header::after{
    content: '';
}

.formato-btngral-header:hover::after{
    height: 0.4vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #0078C1 100%);
    position: absolute;
    bottom: 0%;
    left: -15%;
    width: 130%;
    border-radius: 10vw;
    transition: all 1000ms ease;
}

.formato-btngral-header p{
    margin-bottom: 0.5vw;
}

/* a partir de aquí BOTÓN AZUL para BannerCOVID*/

.formato-btngral-covid{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-covid::after{
    content: '';
    width: 160%;
    height: 0.7vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #25C8F6 0%, #005EFC 59%, #0078C0 100%);
    position: absolute;
    bottom: 0%; left: -28%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-covid:hover::after{
    left: 13%;
    width: 75%;
}

.formato-btngral-covid p{
    margin-bottom: 0.4vw;
}

/* a partir de aquí BOTÓN AZUL para sección covid */

.formato-btngral-covid19{
    cursor: pointer;
    display: inline-block;
    background: none;
    text-decoration: none;
    position: relative;
}

.formato-btngral-covid19::after{
    content: '';
    width: 120%;
    height: 0.95vw;
    background: #00347D;
    background-image: linear-gradient(to bottom, #0078C1 0%, #122E5F 100%);
    position: absolute;
    bottom: 0%; left: -10%;
    border-radius: 10vw;
    transition: all 500ms ease;
}

.formato-btngral-covid19:hover::after{
    left: 0%;
    width: 100%;
}

.formato-btngral-covid19 p{
    margin-bottom: 1.6vw;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .formato-btngral-azul::after{
        width: 110%;
        height: 0.4rem;
        bottom: 0%; left: -5%;
    }
    
    .formato-btngral-azul p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-azulEnviar::after{
        width: 150%;
        height: 0.4rem;
        bottom: 0%; left: -25%;
    }
    
    .formato-btngral-azulEnviar p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-azulbrilloso::after{
        width: 110%;
        height: 0.4rem;
        position: absolute;
        bottom: 0%; left: -5%;
    }
    
    .formato-btngral-azulbrilloso p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-aqua::after{
        width: 150%;
        height: 0.4rem;
        bottom: 0%; left: -25%;
    }
    
    .formato-btngral-aqua p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-asesoria::after{
        width: 100%;
        height: 0.6rem;
        bottom: 0%; left: 0%;
    }
    
    .formato-btngral-asesoria p{
        margin-bottom: 0.8rem;
    }

    .formato-btngral-covid19::after{
        height: 0.52rem;
        left: -8%;
        width: 116%;
    }
    
    .formato-btngral-covid19:hover::after{
        left: -8%;
        width: 116%;
    }
    
    .formato-btngral-covid19 p{
        margin-bottom: 1rem;
    }

}