.sectionHome-desk{
    display: inline;
}

.sectionHome-mobile{
    display: none;
}

.bienvenidahome{
    width: 100%;
    padding: 8% 1% 1% 6%;
}

.box-bienvenidahome{
    width: 100%;
    align-content: space-evenly;
}

.txt-bienvenidahome{
    width: 92%;
}

.txt-vervideo-home{
    position: relative;
    width: 20%;
    left: 30vw;
    display: inline-block;
    text-align: right;
    font-family: Roboto-Medium;
    font-size: 1.5vw;
    letter-spacing: 0px;
    color: #1D3772;
    opacity: 1;
}

.icon-vervideo-home{
    position: absolute;
    margin-right: 1vw;
    width: 4vw;
    max-height: 4vw;
    transform: translate(-50%,-50%);
    top:50%;
    left: 45%;
}

.icon-vervideo-home:hover{
    transform: translate(-50%,-50%) scale(1.25);
    transition: all 500ms ease;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .sectionHome-desk{
        display: none;
    }

    .sectionHome-mobile{
        display: inline;
    }

    .txt-bienvenidahome{
        width: 100%;
    }
}