.container-historiasexitoclientes{
    width: 100%;
}

.div-historiasexitoclientes{
    width: 30%;
    height: 28vw;
    border: 0.25vw solid #0078C1;
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 6px;
    opacity: 1;

    float: left;
    padding: 2% 2% 3%;
    margin-bottom: 3.4%;
    margin-right: 3%;
}

.logo-historiasexitoclientes{
    width: 4vw;
    display: block;
    margin-bottom: 10%;
}

.foto-historiasexitoclientes{
    width: 4vw;
    max-height: 50%;
    border-radius: 6px 6px 0 0;
    float: left;
}

.datoscliente-historiasexitoclientes{
    display: inline-block;
    width: 80%;
}

.nombre-historiasexitoclientes{
    margin-left: 8%;
    font-family: Roboto-Bold;
    font-size: 1.32vw;
    letter-spacing: 0px;
    color: #0078C1;
    opacity: 1;
}

.cargoyempresa-historiasexitoclientes{
    margin-left: 8%;
    font-family: Roboto-Regular;
    font-size: 1.32vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}

.comentario-historiasexitoclientes{
    margin-top: 10%;
    font-family: Roboto-Regular;
    font-size: 1.1vw;
    letter-spacing: 0px;
    color: #122E5F;
    opacity: 1;
}
